export const referrals = [
    {
        how: "Google Search"
    },
    {
        how: "Friend referral"
    },
    {
        how: "Advertisement"
    },
    {
        how: "Other"
    }
]