
import { css } from 'styled-components';

const brandFont = css`
  @font-face {
    font-family: 'Poppins Bold';
    src: url('/fonts/Poppins-Bold') format('truetype');
    font-weight: medium;
    font-style: normal;
  }
`
export { brandFont };