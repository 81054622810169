import React, { useCallback, useState } from "react";
import styled from "styled-components/macro";
import { useDropzone } from "react-dropzone";
import { useAuth } from "../contexts/AuthContext";
import { db, storage } from "../firebase";
import { ref, uploadBytes } from "firebase/storage";
import { useUserContext } from "../contexts/UserContext";
import { arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";
import { BrandButton } from "../styles/shared-styled";

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  height: 20rem;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
`;

const Thumbnail = styled.img`
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
`;

const FileUpload: React.FC = () => {
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const { currentUser } = useAuth();
  const { userData } = useUserContext();
  const [successMessage, setSuccessMessage] = useState(false);
  console.log(userData?.email);

  const submitHandler = async () => {
    if (userData) {
      const storageRef = ref(storage, `/photos/${userData?.companyName}-${userData?.companyId}/${currentUser.uid}/upload`);

      for (const file of imageFiles) {
        // Upload the file to Firebase Storage
        const fileRef = ref(storageRef, file.name);

        // Rest of the file upload logic remains the same as in the previous example
        await uploadBytes(fileRef, file)
          .then((snapshot) => {
            console.log("File uploaded successfully:", snapshot);
            // You can add further logic here if needed
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
            // Handle the error here
          });
      }

      setSuccessMessage(true);

      const companyRef = doc(db, "Company", userData!.companyId);

      const companyDoc = await getDoc(companyRef);

      if (companyDoc.exists()) {
        const companyData = companyDoc.data();

        const employeeIndex = companyData.employeeInfo.findIndex((employee: any) => {
          return employee.email === userData.email;
        });

        if (employeeIndex !== -1) {
          // Clone the employeeInfo array and update the specific user's imagesSubmitted property
          const updatedEmployeeInfo = [...companyData.employeeInfo];
          updatedEmployeeInfo[employeeIndex].imagesSubmitted = true;

          // Update the company document with the updated employeeInfo array
          await updateDoc(companyRef, {
            employeeInfo: updatedEmployeeInfo,
          });
        }
      }
    }
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    // Create image previews for accepted files
    const previews = acceptedFiles.map((file) => URL.createObjectURL(file));
    setImagePreviews((prevPreviews) => [...prevPreviews, ...previews]);
    setImageFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".png"],
    },
    onDrop,
  });

  return (
    <div className="container">
      <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop your photos here, or click to select files</p>
      </Container>

      {successMessage && <p>Images Uploaded Successfully!</p>}

      <ImageContainer>
        {imagePreviews.map((preview, index) => (
          <Thumbnail key={index} src={preview} alt={`Preview ${index}`} />
        ))}
      </ImageContainer>

      <BrandButton $backgroundColor="rgba(97, 159, 222, 1)" onClick={submitHandler}>
        submit
      </BrandButton>
    </div>
  );
};

export default FileUpload;
