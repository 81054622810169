import React from "react";
import styled from "styled-components/macro";
import { ContinerRight, BinRow, BinCol, Title, DetailText, BrandButton, tablet } from "../styles/shared-styled";

import { motion } from "framer-motion";

const SampleImage = styled.img`
  height: 300px;
  width: 300px;
`;

const fadeInAnimationVariants = {
  initial: {
    opacity: 0,
    y: 100,
  },
  animate: (key: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.05 * key,
    },
  }),
};

const ImageDivMobile = styled(motion.div)`
  align-self: center;
  margin-bottom: 1rem;
  ${tablet`
    display:none;
  `}
`;
const ImageDivTablet = styled(motion.div)`
  display: none;
  ${tablet`
    display:block;
  `}
`;

const ExampleTitle = styled(Title)`
  text-align: center;

  ${tablet`
    text-align:left;
  `}
`;

function Examples() {
  return (
    <ContinerRight>
      <BinRow>
        <ImageDivTablet
          key={1}
          variants={fadeInAnimationVariants}
          initial="initial"
          whileInView="animate"
          viewport={{
            once: true,
          }}
        >
          <SampleImage src={`${process.env.PUBLIC_URL}/images/nick-samples.jpg`}></SampleImage>
        </ImageDivTablet>
        <BinCol style={{ height: "100%" }}>
          <motion.div
            key={2}
            variants={fadeInAnimationVariants}
            initial="initial"
            whileInView="animate"
            viewport={{
              once: true,
            }}
          >
            <ExampleTitle style={{ textAlign: "center"}}>
              Send us selfies of your team and let our AI do the magic!</ExampleTitle>
          </motion.div>
          <motion.div
            key={3}
            variants={fadeInAnimationVariants}
            initial="initial"
            whileInView="animate"
            viewport={{
              once: true,
            }}
          >
            <DetailText style={{ textAlign: "center" }}>Traditional headshot sessions are costly and inconvenient.</DetailText>
          </motion.div>
          <motion.div
            key={4}
            variants={fadeInAnimationVariants}
            initial="initial"
            whileInView="animate"
            viewport={{
              once: true,
            }}
          >
            <ImageDivMobile
              key={1}
              variants={fadeInAnimationVariants}
              initial="initial"
              whileInView="animate"
              viewport={{
                once: true,
              }}
            >
              <SampleImage src={`${process.env.PUBLIC_URL}/images/nick-samples.jpg`}></SampleImage>
            </ImageDivMobile>
            <BrandButton $backgroundColor={"rgba(96, 217, 223, 1)"} style={{ alignSelf: "flex-start" }}>
              Get in touch
            </BrandButton>
          </motion.div>
        </BinCol>
      </BinRow>
    </ContinerRight>
  );
}

export default Examples;
