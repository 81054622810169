import { createContext, useContext, useEffect, useState } from "react";
import { updateProfile } from "firebase/auth";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../firebase";
import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Successful login
        console.log("Login successful:", userCredential.user);
        return true;
      })
      .catch((error) => {
        // Handle login error
        console.error("Login error:", error);
        return false;
      });
  }

  function signOut() {
    return auth.signOut();
  }

  async function adminSignUp(email, name, password, company) {
    return createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Successful login
        console.log("Sign up successful:", userCredential.user);
        const companyId = uuidv4();
        const companyRef = doc(db, "Company", companyId);

        await setDoc(companyRef, { id: companyId, companyName: company, employeeInfo: [] });

        const userRef = doc(db, "UserList", email);

        await setDoc(userRef, { email: email, name: name, companyId: companyId, companyName: company, isAdmin: true });
        return true;
      })
      .catch((error) => {
        // Handle login error
        console.error("Sign up error:", error);
        return false;
      });
  }

  async function employeeSignUp(email, name, password, companyName, companyId) {
    return createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Successful login
        console.log("Sign up successful:", userCredential.user);
        const companyRef = doc(db, "Company", companyId);
        const companyDoc = await getDoc(companyRef);

        if (companyDoc.exists()) {
          const companyData = companyDoc.data();

          const existingEmployeeIndex = companyData.employeeInfo.findIndex((employee) => {
            return employee.email === email;
          });

          if (existingEmployeeIndex === -1) {
            await updateDoc(companyRef, {
              employeeInfo: arrayUnion({ email: email, name: name, imagesSubmitted: false, invited: true }), // Use arrayUnion to add eventID to the existing array
            });
          } else {
            const updatedEmployeeInfo = [...companyData.employeeInfo];
            updatedEmployeeInfo[existingEmployeeIndex] = {
              email: email,
              name: name,
              imagesSubmitted: false, // Update any other fields as needed
              invited: true,
            };

            await updateDoc(companyRef, {
              employeeInfo: updatedEmployeeInfo,
            });
          }
        }

        const userRef = doc(db, "UserList", email);

        await setDoc(userRef, { email: email, companyId: companyId, name: name, companyName: companyName, isAdmin: false });
        return true;
      })
      .catch((error) => {
        // Handle login error
        console.error("Sign up error:", error);
        return false;
      });
  }

  function getUser() {
    return auth.currentUser;
  }

  const updateUser = (name, avatar) => {
    updateProfile(auth.currentUser, {
      displayName: name,
      photoURL: avatar,
    });
  };
  // function isAdmin() {
  //   return auth.currentUser.getIdTokenResult().then((idTokenResult) => {
  //     if (!!idTokenResult.claims.admin) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   });
  // }

  // function isEditor() {}

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    getUser,
    login,
    signOut,
    adminSignUp,
    updateUser,
    employeeSignUp,
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
