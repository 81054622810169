import { useEffect, useState } from "react";
import { useUserContext } from "../contexts/UserContext";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { UserData } from "../types";

import personCheck from "../assets/icons/person-check.svg";
import personX from "../assets/icons/person-x.svg";
import styled from "styled-components";
import Tooltip from "../components/Tooltip";
import { tablet } from "../styles/shared-styled";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 4rem;
  flex: 1;
  h2 {
    font-weight: 900;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    text-align: center;
    ${tablet`
      text-align: left;
    `};
  }

  label {
    padding-bottom: 8px;
  }
`;

const UserCard = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;

  flex-wrap: wrap;
  width: 15rem;
`;

const Icon = styled.img`
  width: 3rem;
`;

const MemberSection = styled.div`
  border: 2px solid black;
  padding: 1rem;
  margin-bottom: 1rem;
  h3 {
    margin-bottom: 0.6rem;
  }
`;

const MemberList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

function AdminTools() {
  const { userData } = useUserContext();
  const [teamMemberSize, setTeamMemberSize] = useState();
  // const [submittedMembers, setSubmittedMembers] = useState([
  //   { email: "test", imagesSubmitted: true, invited: true, name: "Philip Aguiar" },
  //   { email: "test", imagesSubmitted: true, invited: true, name: "Philip Aguiar" },
  //   { email: "test", imagesSubmitted: true, invited: true, name: "Philip Aguiar" },
  //   { email: "test", imagesSubmitted: true, invited: true, name: "Philip Aguiar" },
  //   { email: "test", imagesSubmitted: true, invited: true, name: "Philip Aguiar" },
  // ]);
  // const [notSubmittedMembers, setNotSubmittedMembers] = useState([{ email: "test", imagesSubmitted: true, invited: true, name: "philip" }]);

  const [submittedMembers, setSubmittedMembers] = useState<UserData[]>([]);
  const [notSubmittedMembers, setNotSubmittedMembers] = useState<UserData[]>([]);

  useEffect(() => {
    if (userData?.companyId) {
      onSnapshot(doc(db, "Company", userData.companyId), async (doc) => {
        if (doc.exists()) {
          const docData = doc.data();
          setTeamMemberSize(docData.employeeInfo.length);

          docData.employeeInfo.forEach((member: UserData) => {
            if (member.imagesSubmitted) {
              setSubmittedMembers((prevList) => [...prevList, member]);
            } else {
              setNotSubmittedMembers((prevList) => [...prevList, member]);
            }
          });
        }
      });
    }
  }, [userData]);

  return (
    <Container>
      <h2>Team Status</h2>

      {notSubmittedMembers && notSubmittedMembers.length > 0 && (
        <MemberSection>
          <h3>Pending Members</h3>
          <MemberList>
            {notSubmittedMembers.map((member, index) => {
              return (
                <UserCard>
                  <p key={index}>{member.name}</p>

                  {member.imagesSubmitted ? (
                    <Tooltip text={`${member.name} submitted their photos`} borderColor="green">
                      <Icon src={personX} />
                    </Tooltip>
                  ) : (
                    <Tooltip text={`${member.name} has not submitted their photos`} borderColor="red">
                      <Icon src={personX} />
                    </Tooltip>
                  )}
                </UserCard>
              );
            })}{" "}
          </MemberList>
        </MemberSection>
      )}

      {submittedMembers && submittedMembers.length > 0 && (
        <MemberSection>
          <h3>Members Submitted</h3>
          <MemberList>
            {submittedMembers.map((member, index) => {
              return (
                <UserCard>
                  <p key={index}>{member.name}</p>

                  {member.imagesSubmitted ? (
                    <Tooltip text={`${member.name} submitted their photos`} borderColor="green">
                      <Icon src={personCheck} />
                    </Tooltip>
                  ) : (
                    <Tooltip text={`${member.name} has not submitted their photos`} borderColor="red">
                      <Icon src={personX} />
                    </Tooltip>
                  )}
                </UserCard>
              );
            })}{" "}
          </MemberList>
        </MemberSection>
      )}

      {submittedMembers && submittedMembers.length < 1 && notSubmittedMembers && notSubmittedMembers.length < 1 && (
        <p>You haven't invited any team members yet</p>
      )}

      {submittedMembers && submittedMembers.length > 0 && (
        <p>{`${submittedMembers.length}/${submittedMembers.length + notSubmittedMembers.length} have submitted photos`}</p>
      )}
    </Container>
  );
}
export default AdminTools;
