import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import SignUp from "./pages/SignUp";
import Account from "./pages/Account";
import Dashboard from "./components/Dashboard";
import { useAuth } from "./contexts/AuthContext";
import Nav from "./components/Nav";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Pricing from "./pages/Pricing";
import styled from "styled-components/macro";
import Questionnaire from "./pages/Questionnaire";
import EmployeeSignUp from "./pages/EmployeeSignUp";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

function App() {
  const { currentUser } = useAuth();

  return (
    <BrowserRouter>
      <Wrapper>
        <Nav />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signup/:id" element={<EmployeeSignUp />} />

          <Route path="/login" element={<Login />} />
          <Route path="/pricing" element={<Pricing />} />
          {currentUser && <Route path="/account" element={<Dashboard />}></Route>}
          {/* {currentUser && <Route path="/team" element={<Dashboard />}></Route>} */}
          {currentUser && <Route path="/upload" element={<Dashboard />}></Route>}
          {currentUser && <Route path="/download" element={<Dashboard />}></Route>}
          {currentUser && <Route path="/admin" element={<Dashboard />}></Route>}

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Wrapper>
    </BrowserRouter>
  );
}

export default App;
