import React, { useState, ChangeEvent, FormEvent } from "react";
import styled from "styled-components/macro";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { BrandButton, Title } from "../styles/shared-styled";

const Container = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 20px;
  text-align: center;
  color: black;
  @media (max-width: 1080px) {
    max-width: 1080px;
  }
  p {
    padding: 10px 0;
  }
`;


const SignUpForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  width: 400px;
  height: 20px;
  padding: 10px;
  border-radius: 25px;
  padding-left: 20px;
  border: none;
  margin-bottom: 1rem;
  font-size: 1rem;
  text-align: left;
  background-color: #f4f4f4;
  border: solid 1px rgba(0, 0, 0, 0.2);

  ::placeholder {
    opacity: 0.3; /* Set the opacity for the placeholder text */
    font-size: 12px; /* Set the font size for the placeholder text */
  }
`;

const ErrorMessage = styled.p`
  color: #ff0000;
  font-size: 1rem;
  margin-top: 1rem;
  font-weight: 500;
`;

const CompletionMessage = styled.p`
  color: #0bf30b;
  font-size: 1.2rem;
  margin-top: 1.5rem;
`;

function SignUp() {
  const { adminSignUp } = useAuth();
  const navigate = useNavigate();

  const [name, setName] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [completionMessage, setCompletionMessage] = useState<string>("");

  const handleSignUp = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (name.trim() === "" && email.trim() === "" && company.trim() === "") {
      setError("Name, email, and company fields are required.");
    } else if (name.trim() === "") {
      setError("Please enter a valid username.");
    } else if (email.trim() === "") {
      setError("Please enter a valid email.");
    } else if (company.trim() === "") {
      setError("Please enter a valid company name.");
    } else if (!isValidEmail(email)) {
      setError("Invalid email address.");
    } else if (password.trim() === "") {
      setError("Please enter a valid password.");
    } else if (confirmPassword.trim() === "") {
      setError("Please confirm your password.");
    } else if (password !== confirmPassword) {
      setError("Passwords do not match.");
    } else {
      adminSignUp(email, name, password, company).then(async (signedUp: boolean) => {
        if (signedUp) {
          navigate("/account");
        }
      });

      setError("");
      setCompletionMessage("Sign up complete!");
      setTimeout(() => {
        navigate("/");
      }, 3000); // Redirect after 3 seconds (adjust as needed)
    }
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <Container>
      <Title style={{ padding: "20px" }}>Create account</Title>
      <SignUpForm onSubmit={handleSignUp}>
        <Input
          type="text"
          placeholder="Enter name"
          name="name"
          value={name}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
        />

        <Input
          type="email"
          placeholder="Enter email"
          value={email}
          autoComplete="email"
          onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
        />

        <Input
          type="company"
          placeholder="Enter company name"
          value={company}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setCompany(e.target.value)}
        />

        <Input
          type="password"
          placeholder="Create password"
          value={password}
          name="password"
          autoComplete="new-password"
          onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
        />

        <Input
          type="password"
          placeholder="Confirm your password"
          value={confirmPassword}
          autoComplete="new-password"
          onChange={(e: ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
        />

        {error && <ErrorMessage>{error}</ErrorMessage>}

        {completionMessage && <CompletionMessage>{completionMessage}</CompletionMessage>}

        <BrandButton $backgroundColor={"#00B412"} color={"#FFFFFF"} type="submit">
          Sign Up
        </BrandButton>
      </SignUpForm>
      <p>
        Have an account? <Link to="/login">Login</Link>
      </p>
    </Container>
  );
}

export default SignUp;
