import React from 'react';
import styled from 'styled-components';
import {  Logo } from '../styles/shared-styled';
import { Link } from 'react-router-dom';

import logo from '../../src/assets/GroupHeadshotsPro-white.png';

const FooterContainer = styled.div`
  background-color: #141414; /* Charcoal grey */
  color: #ededed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  min-height: 200px; 
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start; 
  flex-wrap: wrap;
  padding: 10px;
  width: 100%;
  flex-grow: 1; 
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledLink = styled(Link)`
  color: #ededed;
  text-decoration-line: none;
`;

const MenuItem = styled.div`
  margin-right: 20px;
  cursor: pointer;
  &:hover {
    color: white;
    transition: 1s;
  }
`;

const DividerAndCopyrightWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Divider = styled.div`
  width: 95%;
  height: 1px;
  background-color: #ededed;
 
`;

const Copyright = styled.p`
margin-top: 20px;
  text-align: center;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <StyledLink to={"/"}>
          <Logo src={logo} alt="groupheadshots pro logo"></Logo>
        </StyledLink>
        <Menu>
          <StyledLink to="/">
            <MenuItem>Home</MenuItem>
          </StyledLink>
          <StyledLink to="/pricing">
            <MenuItem>Pricing</MenuItem>
          </StyledLink>
          <StyledLink to="/">
            <MenuItem>Contact Us</MenuItem>
          </StyledLink>
        </Menu>
      </FooterContent>
      <DividerAndCopyrightWrapper>
        <Divider />
        <Copyright>&copy; 2023 GroupHeadshots.Pro | All Rights Reserved.</Copyright>
      </DividerAndCopyrightWrapper>
    </FooterContainer>
  );
};

export default Footer;
