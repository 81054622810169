import styled from "styled-components/macro";
import { tablet } from "../styles/shared-styled";
import { motion } from "framer-motion";
import Questionnaire from "./Questionnaire";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* flex: 1; */
  /* overflow-y: scroll; */
  padding-top: 2.5%;
  @media (min-height: 1000px) {
    padding-top: 15%;
  }
`;

const Button = styled.button`
  position: absolute;
  border: none;
  border-radius: 25px;
  width: 80%;
  padding: 1rem 0;
  bottom: -1.75rem;
  color: white;
  font-weight: 700;
  font-size: 1.25rem;
  transition: box-shadow 0.3s;
`;
const CardsDiv = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  width: 70%;
  max-width: 1080px;
  box-sizing: border-box;
  margin-bottom: 5%;
  align-items: center;

  ${tablet`flex-direction: row;
   width: 90%;
`}
`;

const PriceCard = styled(motion.div)<{ color: string }>`
  cursor: pointer;
  display: flex;
  position: relative;

  flex-direction: column;
  align-items: center;
  width: 100%;
  border: 2px solid rgba(213, 213, 213, 1);
  border-radius: 10px;
  min-height: 25rem;
  padding: 1rem 2rem;
  box-sizing: border-box;
  transition: border 0.3s, box-shadow 0.3s;
  margin-bottom: 3rem;
  ${tablet`width: 30%;
  margin-bottom:0;
`}

  &:hover {
    border: 3px solid ${({ color }) => color};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  &:hover ${Button} {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  Button {
    background-color: ${({ color }) => color};
  }

  //plan tier
  h3 {
    font-size: 24px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0em;
    margin: 2rem 0;
  }

  //price
  h2 {
    font-weight: 900;
    line-height: 24px;
    font-size: 1rem;
    margin: 1rem 0;
  }

  span {
    font-weight: 900;

    font-size: 2rem;
  }

  //plan details
  ul {
    display: flex;
    flex-direction: column;
    margin: 1rem 0 2rem;
    flex: 1;
    height: 100%;
    width: 100%;
    justify-content: space-around;
    align-items: flex-start;
    list-style: none;
  }

  li {
    font-size: 14px;
    line-height: 21px;
    color: rgba(157, 157, 157, 1);
  }
`;

const Header = styled.h1`
  font-size: 3rem;
  margin: 2rem;
`;

const SubHeader = styled.h3`
  font-size: 2rem;
  margin-top: 2rem;
`;

function Pricing() {
  const cardVariants = {
    initial: {
      opacity: 0,
      scale: 0.8,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5, // Adjust the duration as needed
      },
    },
  };
  return <Questionnaire />;
}
export default Pricing;
