import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { UserData } from "../types";
import { createContext, useContext, useEffect, useState } from "react";
import { db } from "../firebase";
import { useAuth } from "./AuthContext";

type UserContextType = {
  userData: UserData | null;
};

const UserContext = createContext<UserContextType>({
  userData: null,
});

export function useUserContext() {
  return useContext(UserContext);
}
const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      onSnapshot(doc(db, "UserList", currentUser.email), async (doc) => {
        if (doc.exists()) {
          const userData = doc.data() as UserData;
          setUserData(userData);
        }
      });
    }
  }, [currentUser]);

  return (
    <UserContext.Provider
      value={{
        userData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
