import React, { useState } from "react";
import styled from "styled-components";
import { FormInfo } from "../types";
import { BrandButton, BackButton, Container } from "../styles/shared-styled";
import Slide1 from "../components/slides/Slide1";
import Slide2 from "../components/slides/Slide2";
import Slide3 from "../components/slides/Slide3";
import PricingSlide from "../components/slides/PriceSlide";
import { green, turquoise } from "../styles/colors";

type SlideProps = {
  step: number;
};

const SlideContainer = styled(Container)<SlideProps>`
  background-color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: solid 1px white;
  transition: box-shadow 0.5s;
  width: 100%;
  box-sizing: border-box;
  flex: 1;
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 300px;
`

const StepIndicators = styled.div`
  display: flex;
  justify-content: space-between;
  width: 150px; /* Adjust as needed */
  margin: 0 auto;
`;

type StepIndicatorProps = {
  isActive: boolean;
};

const StepIndicator = styled.div<StepIndicatorProps>`
  width: 50px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 0 5px;
  border-radius: 5px;
  transition: background-color 0.3s;

  /* Highlight the current step */
  ${(props) =>
    props.isActive &&
    `
    background-color: rgba(0, 0, 0, 1)
  `}
`;

function Questionnaire() {
  const [step, setStep] = useState(1);
  const [formInfo, setFormInfo] = useState<FormInfo>({
    industry: "",
    background: "",
    referral: "",
  });

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleNext = () => {
    if (step < 6) {
      // Assuming there are 6 steps (0 to 5)
      setStep(step + 1);
    }
  };

  return (
    <SlideContainer step={step}>
      <StepIndicators>
        {[1, 2, 3, 4].map((index) => (
          <StepIndicator key={index} isActive={index === step} />
        ))}
      </StepIndicators>
      <>
        {step === 1 && <Slide1 setStep={setStep} formInfo={formInfo} setFormInfo={setFormInfo} />}
        {step === 2 && <Slide2 setStep={setStep} formInfo={formInfo} setFormInfo={setFormInfo} />}
        {step === 3 && <Slide3 setStep={setStep} formInfo={formInfo} setFormInfo={setFormInfo} />}
        {step === 4 && <PricingSlide />}
      </>

      <div>
        {step !== 4 && (
          <ButtonBox>
            <BackButton width="125px" onClick={handleBack}>
              Back
            </BackButton>
            <BrandButton 
            onClick={handleNext} 
            color="#FFFFFF" 
            width="125px" 
            $backgroundColor={turquoise}>
              Next
            </BrandButton>
          </ButtonBox>
        )}
      </div>
    </SlideContainer>
  );
}

export default Questionnaire;
