import React from "react";
import { Container } from "../styles/shared-styled";
import styled from "styled-components/macro";

import { motion } from "framer-motion";
import { slideUp } from "../styles/shared-animations";

// Import the 'list' from your file
import { list } from "../why-choose-us";

const WhyContainer = styled(Container)`
  justify-content: flex-start;
  margin-bottom: 5rem;
  height: fit-content;

  h1 {
    margin-bottom: 2rem;
    text-align: center;
  }
`;

const List = styled.ul`
  list-style: none;
  width: 80%;
  li {
    display: flex;
    align-items: center;
    width: 100%;
    color: rgba(157, 157, 157, 1);
    margin: 3rem 0;
  }
`;

const Circle = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  min-height: 3rem;
  min-width: 3rem;
  border-radius: 50%;
  margin-right: 2rem;
`;

function WhyChooseUs() {
  return (
    <WhyContainer>
      <h1>Why choose GroupHeadshots?</h1>

      <List>
        {list.map((item, index) => (
          <li key={index}>
            <motion.div
              key={1}
              variants={slideUp}
              initial="initial"
              whileInView="animate"
              viewport={{
                once: true,
              }}
            >
              <Circle color={item.color} />
            </motion.div>
            <motion.div
              key={4}
              variants={slideUp}
              initial="initial"
              whileInView="animate"
              viewport={{
                once: true,
              }}
            >
              {item.text}
            </motion.div>
          </li>
        ))}
      </List>
    </WhyContainer>
  );
}

export default WhyChooseUs;
