import React from "react";
import styled from "styled-components/macro";
import { Navigate, useNavigate } from "react-router-dom";

import { Container, DetailText, Title, BinCol, BrandButton } from "../styles/shared-styled";

function HeroBanner() {
  const navigate = useNavigate();
  return (
    <Container>
      <BinCol>
        <Title>Perfect headshots for your team, powered by AI.</Title>
        <DetailText>
          Effortlessly create visual harmony across your team's portraits. Discover the benefits of AI-generated headshots and make your brand stand
          out today.
        </DetailText>
        <BrandButton onClick={() => navigate("/pricing")} $backgroundColor="rgba(160, 50, 246, 1)"
        color="white">
          Get Started
        </BrandButton>
        <div>
          <p style={{ padding: "10px 0" }}>Join over 100+ companies</p>
        </div>
        <div>IMAGES GO HERE</div>
      </BinCol>
    </Container>
  );
}

export default HeroBanner;
