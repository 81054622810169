// const BASE_URL = "http://127.0.0.1:5001/groupheadshotspro/us-central1/" //testing

const BASE_URL = "https://www.groupheadshots.pro/api";

export const sendEmail = async (email: string, name: string, companyName: string, companyId: string) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({
      email: email,
      company: companyName,
      companyId: companyId,
      name: name,
    }),
  };

  try {
    const response = await fetch(`${BASE_URL}/sendEmail`, requestOptions);
    // console.log(response);
    const data = await response.json();
    console.log("Email sent successfully", data);
  } catch (error) {
    console.error("Error sending email", error);
  }
};
