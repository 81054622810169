import { Link, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import Account from "../pages/Account";
import { useUserContext } from "../contexts/UserContext";
import Upload from "../pages/Upload";
import Download from "../pages/Download";
import { tablet } from "../styles/shared-styled";
import { green, lightBlue, purple, red, blue } from "../styles/colors";
import AdminTools from "../pages/AdminTools";

import account from '../../src/assets/icons/account.svg'
import upload from '../../src/assets/icons/upload.svg'
import download from '../../src/assets/icons/download.svg'
import adminTools from '../../src/assets/icons/admin-tools.svg'



const Page = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${tablet`
      flex-direction: row;
  `}
`;

const Container = styled.div<{ location: string }>`
  background-color: green;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ location }) => location === "/account" && green};
  background-color: ${({ location }) => location === "/upload" && lightBlue};
  background-color: ${({ location }) => location === "/download" && purple};
  background-color: ${({ location }) => location === "/admin" && blue};
  background: linear-gradient(70deg, rgba(28, 202, 206, 1) 0%, rgba(39, 93, 201, 1) 50%, rgba(170, 33, 253, 1) 100%);
  transition: background-color 0.3s;
  box-sizing: border-box;
  ${tablet` width: 30vw;
  max-width: 20rem;
  `}

  h3 {
    height: 3rem;
    margin-bottom: 1rem;
    font-size: 2rem;
  }
`;

const Logo = styled.h1`
  margin: 2rem 2rem 1rem;
`;

const List = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style: none;
  

  ${tablet`   
    flex-direction: column;
    margin-right: auto;
    height: 90vh;
  `}
`;

const ListBin = styled.div<{ $active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0;
  width: 100%;
  box-sizing: border-box;
  height: 5rem;
  border-left: ${({ $active }) => ($active ? "solid 3px white" : "")};

  ${tablet`   

  `}
`
const ListBinBottom = styled.div`
  display: none;
  ${tablet`   
    display: flex;
    justify-self: flex-end;
    margin-top: auto; 
  `}
`;

const ListItem = styled(Link)<{ $active: boolean }>`
  font-weight: ${({ $active }) => ($active ? "700" : "400")};
  opacity: ${({ $active }) => ($active ? "1" : ".4")};
  color: #eeeded;
  text-decoration: none;
`;

const ListIcon = styled.img`
  width: 30px;
  height: 30px;
  margin: 0;
`
const CompanyName = styled.h3`
  font-weight: 700;
  opacity: .5;
  font-size: 14px;
`

const RightPageContainer = styled.div`
  display: flex;
  flex: 1;
  overflow-y: scroll;
  max-height: calc(100vh - 100px);
`;
function Dashboard() {
  const location = useLocation();
  const { userData } = useUserContext();

  return (
    <Page>
      <Container location={location.pathname}>
        {/* <Logo>GroupHeadshots.Pro</Logo> */}
        {/* <div style={{ height: "3rem" }}>{<h3>{userData?.companyName}</h3>}</div> */}

        <List>
          <ListBin $active={location.pathname === "/account"}>
          <ListItem to={"/account"} $active={location.pathname === "/account"}>
            <ListIcon src={account} alt="Account icon"></ListIcon>
            My Account
          </ListItem>
          </ListBin>
          {/* {userData?.isAdmin && (
            <ListItem to={"/team"} $active={location.pathname === "/team"}>
              Team
            </ListItem>
          )} */}
          <ListBin $active={location.pathname === "/upload"}>
            <ListItem to={"/upload"} $active={location.pathname === "/upload"}>
            <ListIcon src={upload} alt="upload icon"></ListIcon>
              Upload
            </ListItem>
          </ListBin>

          <ListBin $active={location.pathname === "/download"}>
            <ListItem to={"/download"} $active={location.pathname === "/download"}>
            <ListIcon src={download} alt="download icon"></ListIcon>
              Download
            </ListItem>
          </ListBin>

          <ListBin $active={location.pathname === "/admin"}>
            {userData?.isAdmin && (
              <ListItem to={"/admin"} $active={location.pathname === "/admin"}>
              <ListIcon src={adminTools} alt="Admin tools icon"></ListIcon>
                Admin Tools
              </ListItem>
            )}
          </ListBin>
          <ListBinBottom>
        {<CompanyName>{userData?.companyName}</CompanyName>}
        </ListBinBottom>   
        </List>
     
      </Container>
      <RightPageContainer>
        {location.pathname === "/account" && <Account />}
        {location.pathname === "/upload" && <Upload />}
        {location.pathname === "/download" && <Download />}
        {location.pathname === "/admin" && <AdminTools />}
      </RightPageContainer>
    </Page>
  );
}
export default Dashboard;
