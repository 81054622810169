import styled from "styled-components/macro";
import { BrandButton, Input, InputDiv } from "../styles/shared-styled";
import { useUserContext } from "../contexts/UserContext";
import { useEffect, useState } from "react";
import { UserData } from "../types";
import GreenCheck from "../assets/icons/green-check.png";
import Warning from "../assets/icons/yellow-exclamation-mark.jpg";

import { green, purple } from "../styles/colors";
import { sendEmail } from "../utils/sendEmail";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import Tooltip from "../components/Tooltip";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 4rem;
  flex: 1;
  h2 {
    font-weight: 900;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }

  label {
    padding-bottom: 8px;
  }
`;

const TeamInvitesDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 2px solid black;
  margin-bottom: 1rem;
  padding: 0.5rem;
`;

const TeamMemberDiv = styled.div<{ $active: boolean }>`
  cursor: pointer;
  width: auto;
  display: flex;
  align-items: center;
  padding: 0.4rem;
  margin: 0.5rem;
  min-height: 1rem;
  border: 1px solid ${({ $active }) => ($active ? "black" : "transparent")};

  &:hover {
    background-color: lightgray\;;
  }
`;

const TeamMemberInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 0.5rem;

  p:first-of-type {
    padding-bottom: 3px;
  }
`;

const Icon = styled.img`
  height: 2.8rem;
`;

const ListButtonsDiv = styled.div`
  display: flex;
`;

const ListButton = styled(BrandButton)`
  align-self: flex-start;
  width: 3rem;
  height: 2rem;
  background-color: ${green};
  &:first-of-type {
    margin-right: 0.5rem;
  }
`;

function Account() {
  const { userData } = useUserContext();
  const [teamMembers, setTeamMembers] = useState<UserData[]>([]);
  const [activeMember, setActiveMember] = useState<UserData>();
  const [activeMemberIndex, setActiveMemberIndex] = useState<number>(0);

  useEffect(() => {
    setActiveMember(teamMembers[activeMemberIndex]);
  }, [activeMemberIndex]);

  useEffect(() => {
    if (userData?.companyId) {
      onSnapshot(doc(db, "Company", userData.companyId), async (doc) => {
        if (doc.exists()) {
          const docData = doc.data();

          setTeamMembers(docData.employeeInfo);
          setActiveMemberIndex(docData.employeeInfo.length);
        }
      });
    }
  }, [userData]);

  useEffect(() => {
    console.log("teamMembers: ", teamMembers);
  }, [teamMembers]);

  useEffect(() => {
    console.log("activeMember :", activeMember);
  }, [activeMember]);

  useEffect(() => {
    console.log("activeMemberIndex:", activeMemberIndex);
  }, [activeMemberIndex]);

  const addMember = () => {
    const newTeamMembers = [...teamMembers];

    newTeamMembers[activeMemberIndex] = activeMember!;

    setTeamMembers(newTeamMembers);

    setActiveMember({
      email: "",
      companyName: userData!.companyName,
      companyId: userData!.companyId,
      name: "",
      isAdmin: false,
    });

    setActiveMemberIndex(newTeamMembers.length);
  };

  const handleSubmit = () => {
    teamMembers.forEach(async (member) => {
      sendEmail(member.email, member.name, userData!.companyName, userData!.companyId);
    });
  };

  return (
    <Container>
      <h2>My Account</h2>

      <label>Name</label>
      <Input type="text" placeholder={userData?.name || "Enter name"} disabled />

      <label>Email</label>
      <Input type="email" placeholder={userData?.email} disabled />

      <label>Company</label>
      <Input type="text" placeholder={userData?.companyName} disabled />

      {userData && userData.isAdmin && (
        <>
          <label>Team Members</label>

          {teamMembers && (
            <>
              <TeamInvitesDiv>
                {teamMembers.length > 0 ? (
                  teamMembers.map((member, index) => {
                    return (
                      <TeamMemberDiv
                        key={index}
                        $active={index === activeMemberIndex}
                        onClick={() => {
                          setActiveMemberIndex(index);
                        }}
                      >
                        <TeamMemberInfo>
                          <p>{member.name}</p>
                          <p>{member.email}</p>
                        </TeamMemberInfo>
                        {member.invited ? (
                          <Tooltip text="Invite has been sent" borderColor="limegreen">
                            <Icon src={GreenCheck} alt="" />{" "}
                          </Tooltip>
                        ) : (
                          <Tooltip text="Invite has not been sent yet" borderColor="limegreen">
                            <Icon src={Warning} alt="" />
                          </Tooltip>
                        )}
                      </TeamMemberDiv>
                    );
                  })
                ) : (
                  <p>You haven't invited any team members yet.</p>
                )}

                {/* <BrandButton
              $backgroundColor="rgba(194, 255, 146, 1)"
              onClick={() => {
                setActiveMemberIndex(teamMembers.length);
              }}
            >
              {"+"}
            </BrandButton> */}
              </TeamInvitesDiv>
            </>
          )}

          <label>Add Member</label>

          <Input
            type="text"
            placeholder="Name"
            value={activeMember ? activeMember.name : ""}
            onChange={(e) => {
              setActiveMember((prevInfo) => {
                return { ...prevInfo!, name: e.target.value };
              });
            }}
          />
          <Input
            type="email"
            placeholder="Email"
            value={activeMember ? activeMember.email : ""}
            onChange={(e) => {
              setActiveMember((prevInfo) => {
                return { ...prevInfo!, email: e.target.value };
              });
            }}
          />
          <BrandButton
            $backgroundColor={purple}
            onClick={addMember}
            disabled={!activeMember || !activeMember.name || !activeMember.email}
          >
            Add Member
          </BrandButton>

          <BrandButton onClick={handleSubmit}>Send Invites</BrandButton>

          <ListButtonsDiv>
            <ListButton
              disabled={activeMemberIndex === 0}
              onClick={() => {
                setActiveMemberIndex((prevIndex) => prevIndex - 1);
              }}
            >
              {"<"}
            </ListButton>
            <ListButton
              disabled={activeMemberIndex === teamMembers.length}
              onClick={() => {
                setActiveMemberIndex((prevIndex) => prevIndex + 1);
              }}
            >
              {">"}
            </ListButton>
          </ListButtonsDiv>
        </>
      )}
    </Container>
  );
}
export default Account;
