export const list = [
    {
      text: "Accuracy - our system outputs perfect headshots utilizing the most current photography and post processing trends used by photographers. How do we know? We are photographers!",
      color: "rgba(180, 183, 249, 1)"
    },
    {
        text:  "Scalability - how many times has a new employee joined your team, but missed photo day? We save your team model so we can simply render new members according to your teams look.",
        color: "rgba(43, 211, 240, 1)"
    },
    {
        text:  "Cost Effective - AI headshots are less work for everybody and yield comparable if not better results than traditional headshots.",
        color: "rgba(39, 93, 201, 1)"
    },
    {
        text:  "Custom made - we customize models according to your specific requirements.",
        color: "rgba(170, 33, 253, 1)"
    },
    {
        text:  "Flawless results - top tier photographers use photoshop to remove blemishes and enhance photos 99% of the time. with AI headshots you render a perfect headshot from the start!.",
        color: "rgba(249, 180, 180, 1)"
    },
    {
        text:  "100% Money back guarantee - We are confident you will love the final results, and if for whatever reason you don’t use the photos, we’ll refund your payment.",
        color: "rgba(209, 255, 134, 1)"
    }
  ];