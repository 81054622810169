import styled, { css, keyframes } from "styled-components/macro";
import { useState } from "react";
import { brandFont } from "../styles/fonts";
import { useAuth } from "../contexts/AuthContext";
import { BrandButton, Logo, StyledLink, StyledNavLink } from "../styles/shared-styled";
import { blue } from "../styles/colors";
import { Navigate } from "react-router-dom";

import logo from '../../src/assets/GroupHeadshotsPro-black.png'

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: 100px;
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const BannerContainer = styled.div`
  /* padding: 15px; */
  width: 100%;
  box-sizing: border-box;
`;

const NavContainer = styled.div`
  display: flex;
  max-width: 1080px;
  margin: 0 auto;
  justify-content: space-between;
  height: 100px;
  align-items: center;
  background-color: #ffffff;

  padding: 0 20px; /* Adjust padding here */

  @media (max-width: 768px) {
    padding: 20px 20px; /* Adjust padding for mobile */
  }
`;

const NavItems = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavItem = styled.li`
  margin: 0 15px;
  font-weight: 500;
  color: #363636;
  cursor: pointer;
  transition: color 0.3s, text-shadow 0.3s; /* Added transition properties */

  &:hover {
    color: black;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Cool text shadow effect */
  }

  &:last-child {
    margin-right: 0;
  }
`;

type MobileNavItemsProps = {
  $isOpen: boolean;
};

const MobileNavItems = styled(NavItems)<MobileNavItemsProps>`
  flex-direction: column;
  position: absolute;
  top: 70px;
  right: 20px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: none;

  ${NavItem} {
    margin: 10px 0;
  }

  @media (max-width: 768px) {
    ${({ $isOpen }) =>
      $isOpen
        ? css`
            display: flex;
          `
        : css`
            display: none;
          `}
  }
`;

const FlexSpacer = styled.div`
  flex: 1;
`;

const MobileNavToggle = styled.div`
  display: none;
  cursor: pointer;
  padding: 10px;

  @media (max-width: 768px) {
    display: block;
  }
`;

const HamburgerIcon = styled.div`
  width: 30px;
  height: 3px;
  background-color: black;
  margin: 4px 0;

  &:first-child {
    width: 20px;
  }

  &:last-child {
    width: 20px;
  }
`;

function Nav() {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const { currentUser, signOut } = useAuth();
  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <PageContainer>
      <BannerContainer>
        <NavContainer>
          <StyledLink to={"/"}>
            <Logo src={logo} alt="groupheadshots pro logo"></Logo>
            {/* <h4 style={{ color: "black" }}>
              GroupHeadshots.<span style={{ fontWeight: "bold" }}>PRO</span>
            </h4> */}
          </StyledLink>
          <FlexSpacer />
          {!currentUser && (
            <NavItems>
              <StyledNavLink to="/events">
                <NavItem>How it works</NavItem>
              </StyledNavLink>
              <StyledNavLink to="/Pricing">
                <NavItem>Pricing</NavItem>{" "}
              </StyledNavLink>
            </NavItems>
          )}
          <FlexSpacer />

          <NavItems>
            {currentUser ? (
              <NavItem onClick={signOut}>Sign out</NavItem>
            ) : (
              <>
                <StyledNavLink to="/login">
                  <NavItem>Login</NavItem>
                </StyledNavLink>
                <StyledNavLink to="/signup">
                <BrandButton
                  color="#FFFFFF"
                  width="125px"
                  background='linear-gradient(to right, rgba(10, 57, 178, 1), rgba(96, 217, 223, 1))'
                >
                  Sign up
                </BrandButton>
                </StyledNavLink>
              </>
            )}
            {currentUser && (
              <StyledNavLink to="/account">
                <BrandButton>Dashboard</BrandButton>
              </StyledNavLink>
            )}
          </NavItems>

          <MobileNavToggle onClick={toggleMobileNav}>
            <HamburgerIcon />
            <HamburgerIcon />
            <HamburgerIcon />
          </MobileNavToggle>
          <MobileNavItems $isOpen={isMobileNavOpen}>
            {currentUser && (
              <>
                <StyledNavLink to="/account">
                  <NavItem>Dashboard</NavItem>
                </StyledNavLink>
                {/* <StyledNavLink to="/create">
                  <NavItem>Create Event</NavItem>
                </StyledNavLink> */}
                <NavItem>Resources</NavItem>
              </>
            )}
            {currentUser ? (
              <NavItem onClick={signOut}>Sign out</NavItem>
            ) : (
              <StyledNavLink to="/">
                <NavItem>Login</NavItem>
              </StyledNavLink>
            )}
          </MobileNavItems>
        </NavContainer>
      </BannerContainer>
    </PageContainer>
  );
}

export default Nav;
