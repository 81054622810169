import React, { useState, useEffect, useRef } from "react";
import HeroBanner from "../components/HeroBanner";
import TeamComparison from "../components/TeamComparison";
import Examples from "../components/Examples";
import WhyChooseUs from "../components/WhyChooseUs";
import Footer from "../components/Footer";

function Home() {
  return (
    <>
      <HeroBanner />
      <TeamComparison />
      <Examples />
      <WhyChooseUs />
      <Footer />
    </>
  );
}

export default Home;
