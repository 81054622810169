import { useState } from "react";
import { BrandButton, Input, InputDiv } from "../styles/shared-styled";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import styled from "styled-components/macro";

const Container = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15%;
  text-align: center;
  color: black;
  @media (max-width: 1080px) {
    max-width: 1080px;
  }

  h1 {
    margin-bottom: 1rem;
  }
  p{
    padding: 10px 0;
  }
`;

function Login() {
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();

  const { login } = useAuth();
  const navigate = useNavigate();

  return (
    <Container>
      <h1>Login</h1>

      <Input
        type="email"
        name="email"
        placeholder="Enter Email"
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        autoComplete=""
      />

      <Input
        name="password"
        type="password"
        placeholder="Enter Password"
        onChange={(e) => {
          setPassword(e.target.value);
        }}
      />

      <BrandButton
        $backgroundColor={"#00B412"}
        color={"#FFFFFF"}
        type="submit"
        onClick={async () => {
          if (await login(email, password)) {
            navigate("/account");
          }
        }}
      >
        Login
      </BrandButton>
      <p>
        Don't have an account? <Link to="/signup">Sign up</Link>
      </p>
    </Container>
  );
}
export default Login;
