import React from "react";
import styled from "styled-components/macro";
import { brandFont } from "../styles/fonts";
import { ContainerLeft, Title, DetailText, BinCol, BinRow, BrandButton, tablet } from "../styles/shared-styled";

import { motion } from "framer-motion";

const teamAImages = ["1.jpg", "2.jpg", "3.jpg", "4.jpg"];
const teamBImages = ["1.jpg", "2.jpg", "3.jpg", "4.jpg"];

const TeamImage = styled.img`
  width: 45%;
`;

const fadeInAnimationVariants = {
  initial: {
    opacity: 0,
    y: 100,
  },
  animate: (key: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.05 * key,
    },
  }),
};
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 20px;
  ${tablet`justify-content: flex-start;`}
`;

const TeamDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    align-self: flex-start;
    margin: 0.5rem 0 0.5rem calc(5% - 0.5rem);
    margin-left: 20px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  img {
    &:nth-child(-n + 2) {
      /* Your styles for the first two children here */
      margin-bottom: 0.5rem;
    }

    &:nth-child(1),
    &:nth-child(3) {
      /* Your styles for the first two children here */
      margin-right: 0.5rem;
    }
  }
`;

function TeamComparison() {
  return (
    <ContainerLeft>
      <motion.div
        key={1}
        variants={fadeInAnimationVariants}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
      >
        <Title style={{ marginLeft: "20px" }}>
          Which team would you prefer to work with?
        </Title>
      </motion.div>
      <motion.div
        key={2}
        variants={fadeInAnimationVariants}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
      >
        <DetailText style={{ marginLeft: "20px" }}>
          The fastest and easiest way to get your team unified professional corporate headshots</DetailText>
        <ButtonWrapper>
          <BrandButton color="white" $backgroundColor="rgba(97, 159, 222, 1)">
            Try for free
          </BrandButton>
        </ButtonWrapper>
      </motion.div>
      <motion.div
        key={3}
        variants={fadeInAnimationVariants}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
      >
        <BinRow>
          <TeamDiv>
            <p>Team A</p>
            <ImageWrapper>
              {teamAImages.map((imageName, index) => (
                <TeamImage key={index} src={`${process.env.PUBLIC_URL}/images/team-a/${imageName}`} alt={`Team A Image ${index + 1}`} />
              ))}
            </ImageWrapper>
          </TeamDiv>
          <TeamDiv>
            <p>Team B</p>
            <ImageWrapper>
              {teamBImages.map((imageName, index) => (
                <TeamImage key={index} src={`${process.env.PUBLIC_URL}/images/team-a/${imageName}`} alt={`Team A Image ${index + 1}`} />
              ))}
            </ImageWrapper>
          </TeamDiv>
        </BinRow>
      </motion.div>
    </ContainerLeft>
  );
}

export default TeamComparison;
