import styled from "styled-components/macro";
import FileUpload from "../components/FileUpload";
import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useUserContext } from "../contexts/UserContext";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import { storage } from "../firebase";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 4rem;
  flex: 1;

  h2 {
    font-weight: 900;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Image = styled.img`
  width: 10rem;
  margin: 0.5rem;
`;

function Upload() {
  const [images, setImages] = useState<string[]>([]);
  const { currentUser } = useAuth();
  const { userData } = useUserContext();

  useEffect(() => {
    if (currentUser && currentUser.uid && userData) {
      const storageRef = ref(storage, `/photos/${userData?.companyName}-${userData?.companyId}/${currentUser.uid}/upload`);

      listAll(storageRef)
        .then(async (result) => {
          const imagePromises = result.items.map(async (item) => {
            // Filter for image files (you can customize the filter based on your file naming convention)
            if (item.name.match(/\.(jpg|jpeg|png|gif)$/)) {
              return getDownloadURL(item);
            }
            return null;
          });

          // Resolve all promises for image URLs
          const resolvedUrls = await Promise.all(imagePromises);

          // Filter out null values (non-image files)
          const filteredUrls = resolvedUrls.filter((url) => url !== null) as string[];
          // Set the image URLs
          setImages(filteredUrls);
        })
        .catch((error) => {
          console.error("Error listing files:", error);
        });
    }
  }, [currentUser, userData]);

  return (
    <Container>
      <h2>Upload</h2>
      <FileUpload />

      {images && images.length > 0 && (
        <ImageContainer>
          {images.map((image, index) => {
            return <Image key={index} src={image} />;
          })}
        </ImageContainer>
      )}
    </Container>
  );
}
export default Upload;
