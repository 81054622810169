import styled from "styled-components/macro";
import FileUpload from "../components/FileUpload";
import { useAuth } from "../contexts/AuthContext";
import { useUserContext } from "../contexts/UserContext";
import { useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db, storage } from "../firebase";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import { BrandButton } from "../styles/shared-styled";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 4rem;
  flex: 1;

  h2 {
    font-weight: 900;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
`;

const Image = styled.img`
  width: 100%;
  margin: 0.5rem;
`;

function Download() {
  const [images, setImages] = useState<string[]>([]);

  const { currentUser } = useAuth();
  const { userData } = useUserContext();

  const downloadAllImages = () => {
    images.forEach((image, index) => {
      // Create an anchor element
      const anchor = document.createElement("a");
      anchor.href = image;
      anchor.download = `image_${index}.jpg`;
      anchor.style.display = "none";

      // Append the anchor to the body and trigger the click event
      document.body.appendChild(anchor);
      anchor.click();

      // Remove the anchor element from the DOM
      document.body.removeChild(anchor);
    });
  };

  useEffect(() => {
    if (currentUser && currentUser.uid && userData) {
      const storageRef = ref(storage, `/photos/${userData?.companyName}-${userData?.companyId}/${currentUser.uid}/download`);

      listAll(storageRef)
        .then(async (result) => {
          const imagePromises = result.items.map(async (item) => {
            // Filter for image files (you can customize the filter based on your file naming convention)
            if (item.name.match(/\.(jpg|jpeg|png|gif)$/)) {
              return getDownloadURL(item);
            }
            return null;
          });

          // Resolve all promises for image URLs
          const resolvedUrls = await Promise.all(imagePromises);

          // Filter out null values (non-image files)
          const filteredUrls = resolvedUrls.filter((url) => url !== null) as string[];
          // Set the image URLs
          setImages(filteredUrls);
        })
        .catch((error) => {
          console.error("Error listing files:", error);
        });
    }
  }, [currentUser, userData]);

  return (
    <Container>
      <h2>Download</h2>

      {images && images.length > 0 ? (
        images.map((image) => {
          return <Image src={image} />;
        })
      ) : (
        <p>Images are not available yet</p>
      )}

      <BrandButton onClick={downloadAllImages} disabled={images.length === 0} $backgroundColor="rgba(249, 180, 180, 1)">
        Download All Images
      </BrandButton>
    </Container>
  );
}
export default Download;
