


export const slideUp = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: (key: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.05 * key,
      },
    }),
  };