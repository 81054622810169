import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { backgrounds } from "../../lists/backgrounds";
import arrowLeft from '../../assets/icons/arrow-left.svg'
import arrowRight from '../../assets/icons/arrow-right.svg'
import checkmark from '../../assets/icons/green-check.png'
import { FormInfo } from "../../types";
import { Title } from "../../styles/shared-styled";

const Row = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center; 
`
const Col = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    h3 {
        padding: 30px 0;
    }
`

type CardProps = {
    active: boolean;
}

const Card = styled(motion.div)<CardProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 150px; 
  min-width: 150px;
  max-width: 150px; 
  border:  ${(props) => (props.active ? "solid 2px #00B412" : "solid 1px rgb(0,0,0,0.3)")};
  border-radius: 10px;
  margin: 10px;
  cursor: pointer;
  @media (min-width: 768px) {
    width: 22%; /* Cards take up 25% of the width */
  }
`;

const CardIcon = styled.img`
    width: 40px;
    height: 40px;
    opacity: .6;
`
const CardLabel = styled.p`
    font-size: 14px;
    font-weight: 800;
    opacity: .5;
    text-align: center;
`

const Checkmark = styled.img<{ show: boolean }>`
    position: absolute;
    width: 40px;
    height: 40px;
    top: 10px;
    right: 10px;
    opacity: ${(props) => (props.show ? "1" : "0")};
    transition: opacity 0.3s ease-in-out;
`;

const SquareContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center; 
  align-items: center; 
`;

type NavArrowProps = {
    disabled: boolean;
};

const NavArrow = styled.img<NavArrowProps>`
  width: 100px;
  height: 100px;
  opacity: ${(props) => (props.disabled ? "0.3" : "1")};
  cursor: pointer;
  margin: 0 45px;
`;

interface Slide2Props {
  setFormInfo: React.Dispatch<React.SetStateAction<FormInfo>>;
  formInfo: FormInfo;
  setStep: Function;
}

const Slide2:  React.FC<Slide2Props> = ({ setStep, setFormInfo, formInfo }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [clickedBackground, setClickedBackground] = useState<string | null>(null); // Store the industry string of the clicked card
  const [background, setBackground] = useState("");

  const cardsPerPage = 4;
  const totalCards = backgrounds.length;

  const startIndex = currentIndex * cardsPerPage;
  const endIndex = Math.min(startIndex + cardsPerPage, totalCards);

  const handleNext = () => {
    if (endIndex < totalCards) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  // Adjust the number of cards per row based on the window size
  const adjustCardsPerRow = () => {
    if (window.innerWidth >= 768) {
      return 2; // On tablet or larger, show 2 cards per row
    } else {
      return 1; // On smaller screens, show 1 card per row
    }
  };

  const cardsPerRow = adjustCardsPerRow();

  useEffect(() => {
    // Recalculate the number of cards per row when the window is resized
    const handleResize = () => {
      setCurrentIndex(0); // Reset to the first page when window resizes
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCardClick = (background: string) => {
    setClickedBackground(background); // Store the industry string of the clicked card
    setBackground(background)
    console.log(background)
    setFormInfo((prevFormInfo) => ({
      ...prevFormInfo,
      background: background,
    }));
    
  };

  console.log("Form info: ", formInfo)
  return (
    <Col>
       <Title style={{ padding: "30px", textAlign: "center" }}>What background do you want?</Title>
      <Row>
        <SquareContainer>
          <AnimatePresence>
            {backgrounds.slice(startIndex, endIndex).map((backgroundItem, index) => (
              <Card
                active={clickedBackground === backgroundItem.background}
                onClick={() => handleCardClick(backgroundItem.background)}
                key={index}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
              >
                {/* <CardIcon src={backgroundItem.link} alt={backgroundItem.background} /> */}
                <CardLabel>{backgroundItem.background}</CardLabel>
                <Checkmark src={checkmark} show={clickedBackground === backgroundItem.background} />
              </Card>
            ))}
          </AnimatePresence>
        </SquareContainer>
      </Row>
      <Row>
        <NavArrow src={arrowLeft} onClick={handlePrev} disabled={currentIndex === 0} />
        <NavArrow src={arrowRight} onClick={handleNext} disabled={endIndex >= totalCards} />
      </Row>
    </Col>
  );
};

export default Slide2;
