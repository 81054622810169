import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import "firebase/storage";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDyF86m1bbkKLw6RKrBcLIXGJe5LVMYzIk",
  authDomain: "groupheadshotspro.firebaseapp.com",
  projectId: "groupheadshotspro",
  storageBucket: "groupheadshotspro.appspot.com",
  messagingSenderId: "1048076615622",
  appId: "1:1048076615622:web:a2e256a3d127ccc2f97cc7",
  measurementId: "G-ET4MYWVPSN",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const userAuth = getAuth();
export const db = getFirestore(app);
export const storage = getStorage(app);
