export const backgrounds = [
    {
        background: "Brick"
    },
    {
        background: "White"
    },
    {
        background: "Black"
    },
    {
        background: "Grey"
    },
    {
        background: "Navy"
    },
    {
        background: "Cityscape"
    },
    {
        background: "Nature"
    },
    {
        background: "Not sure yet"
    }
]