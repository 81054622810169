import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import styled from "styled-components/macro";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { BrandButton, Title } from "../styles/shared-styled";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

const Container = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 20px;
  text-align: center;
  color: black;
  @media (max-width: 1080px) {
    max-width: 1080px;
  }
`;

const Header = styled.h1`
  margin-top: 0;
  font-size: 2.5rem;
  color: white;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.4), 0 0 10px rgba(255, 255, 255, 0.4), 0 0 15px rgba(255, 255, 255, 0.4),
    0 0 20px rgba(255, 255, 255, 0.4), 0 0 25px rgba(255, 255, 255, 0.4);
`;

const SignUpForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  width: 400px;
  height: 20px;
  padding: 10px;
  border-radius: 25px;
  padding-left: 20px;
  border: none;
  margin-bottom: 1rem;
  font-size: 1rem;
  text-align: left;
  background-color: #f4f4f4;
  border: solid 1px rgba(0, 0, 0, 0.2);

  ::placeholder {
    opacity: 0.3; /* Set the opacity for the placeholder text */
    font-size: 12px; /* Set the font size for the placeholder text */
  }
`;

const Button = styled.button`
  margin-top: 1rem;
  width: 200px;
  height: 50px;
  border: solid 1px white;
  background-color: transparent;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

  &:hover {
    background-color: white;
    color: #007bff;
  }
`;

const ErrorMessage = styled.p`
  color: #ff0000;
  font-size: 1rem;
  margin-top: 1rem;
  font-weight: 500;
`;

const CompletionMessage = styled.p`
  color: #0bf30b;
  font-size: 1.2rem;
  margin-top: 1.5rem;
`;

function EmployeeSignUp() {
  const { employeeSignUp } = useAuth();
  const navigate = useNavigate();

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [completionMessage, setCompletionMessage] = useState<string>("");

  const { id } = useParams();
  const [company, setCompany] = useState("");

  useEffect(() => {
    const getCompanyInfo = async () => {
      const companyRef = doc(db, "Company", id!);
      const companyDoc = await getDoc(companyRef);

      setCompany(companyDoc.data()?.companyName);
    };

    if (id) {
      getCompanyInfo();
    }
  }, [id]);

  const handleSignUp = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (name.trim() === "" && email.trim() === "" && company.trim() === "") {
      setError("Name, email, and company fields are required.");
    } else if (name.trim() === "") {
      setError("Please enter a valid username.");
    } else if (email.trim() === "") {
      setError("Please enter a valid email.");
    } else if (company.trim() === "") {
      setError("Please enter a valid company name.");
    } else if (!isValidEmail(email)) {
      setError("Invalid email address.");
    } else if (password.trim() === "") {
      setError("Please enter a valid password.");
    } else if (confirmPassword.trim() === "") {
      setError("Please confirm your password.");
    } else if (password !== confirmPassword) {
      setError("Passwords do not match.");
    } else {
      employeeSignUp(email, name, password, company, id).then(async (signedUp: boolean) => {
        if (signedUp) {
          navigate("/account");
        }
      });

      setError("");
      setCompletionMessage("Sign up complete!");

      setTimeout(() => {
        setCompletionMessage("");
      }, 5000);
    }
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <Container>
      <h2>{company}</h2>

      <Title style={{ padding: "20px" }}>Create account</Title>
      <SignUpForm onSubmit={handleSignUp}>
        <Input type="text" placeholder="Enter name" value={name} onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)} />

        <Input type="email" placeholder="Enter email" value={email} onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} />

        <Input type="company" placeholder="Enter company name" value={company} disabled />

        <Input
          type="password"
          placeholder="Create password"
          value={password}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
        />

        <Input
          type="password"
          placeholder="Confirm your password"
          value={confirmPassword}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
        />

        {error && <ErrorMessage>{error}</ErrorMessage>}

        {completionMessage && <CompletionMessage>{completionMessage}</CompletionMessage>}

        <BrandButton $backgroundColor={"#00B412"} color={"#FFFFFF"} type="submit">
          Sign Up
        </BrandButton>
      </SignUpForm>
      <p>
        Have an account? <Link to="/login">Login</Link>
      </p>
    </Container>
  );
}

export default EmployeeSignUp;
