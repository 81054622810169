import styled, { css } from "styled-components/macro";
import { Link } from "react-router-dom";
import { brandFont } from "./fonts";
import { ButtonHTMLAttributes } from "react";

export const tablet = (styles: TemplateStringsArray) => css`
  @media (min-width: 768px) {
    ${css(styles)}
  }
`;

export const desktop = (styles: TemplateStringsArray) => css`
  @media (min-width: 1024px) {
    ${css(styles)}
  }
`;

export const Logo = styled.img`
  width: 250px;
`;

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  height: calc(100vh - 120px);
  /* border: 1px solid blue; */
  padding: 20px;
`;

export const ContainerLeft = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  height: calc(100vh - 120px);
  /* border: 1px solid purple; */
  text-align: left;
  padding: 20px;

  ${tablet`align-items: flex-start;`}
`;

export const ContinerRight = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  height: calc(100vh - 120px);
  /* border: 1px solid purple; */
  text-align: left;
  padding: 20px;
`;

export const BinCol = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: ${brandFont};
  padding: 20px;
  width: 100%;
  p {
    font-size: 14px;
  }
  ${tablet`
  width: 45%;
  `}
`;

export const BinRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h1`
  padding: 0;
  text-align: center;
  ${tablet`text-align:left;`}
`;

export const DetailText = styled.h4`
  padding: 10px 0 20px 0;
  opacity: 0.3;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  ${tablet`text-align:left;`}
`;

export const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 1rem 0;
  label {
    margin-bottom: 0.4rem;
  }

  input {
    padding: 0.5rem;
    padding-left: 1.5rem;
    box-sizing: border-box;
    background: #fafafa;
    border: 1px solid #7f7f7f;
    border-radius: 25px;

    &::placeholder {
      color: #bbbbbb;
    }
  }
`;

type BrandButtonProps = {
  width?: string;
  color?: string;
  background?: string;
  $backgroundColor?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const BrandButton = styled.button<BrandButtonProps>`
  align-self: center;
  width: ${(props) => props.width || "150px"};
  min-height: 40px;
  border-radius: 25px;
  outline: none;
  border: none;
  font-weight: 600;
  cursor: pointer;
  background-color: ${(props) => props.$backgroundColor || "lightblue"};
  background: ${(props) => props.background || ""};
  color: ${(props) => (props.color ? "white" : "black")};

  &:disabled {
    opacity: 0.5;
  }
`;

export const BackButton = styled.button<BrandButtonProps>`
  align-self: center;
  width: ${(props) => props.width || "200px"};
  height: 40px;
  border-radius: 25px;
  outline: none;
  border: none;
  font-weight: 600;
  background: transparent;

  cursor: pointer;
  border: solid 1px black;
  color: black;
`;

export const StyledNavLink = styled(Link)`
  text-decoration-line: none;
  margin-right: 10px;
  color: black;
`;

export const StyledLink = styled(Link)`
  text-decoration-line: none;
`;

export const LogoText = styled.h2`
  font-weight: 900;
  letter-spacing: -0.07em;
  margin: 0;
  color: black;
`;

export const Input = styled.input`
  width: 400px;
  height: 20px;
  padding: 10px;
  border-radius: 25px;
  padding-left: 20px;
  border: none;
  margin-bottom: 1rem;
  font-size: 1rem;
  text-align: left;
  background-color: #f4f4f4;
  border: solid 1px rgba(0, 0, 0, 0.2);

  ::placeholder {
    opacity: 0.3; /* Set the opacity for the placeholder text */
    font-size: 12px; /* Set the font size for the placeholder text */
  }
`;
