export const industries = [
    {
      industry: "Healthcare and Medical Services",
      link: "../icons/healthcare.svg"
    },
    {
      industry: "Information Technology (IT)",
      link: "../icons/code.svg"
    },
    {
      industry: "Education and Academia",
      link: "../icons/education.svg"
    },
    {
      industry: "Finance and Banking",
      link: "../icons/finance.svg"
    },
    {
      industry: "Retail and Consumer Goods",
      link: "../icons/retail.svg"
    },
    {
      industry: "Hospitality and Tourism",
      link: "../icons/hospitality.svg"
    },
    {
      industry: "Government and Public Administration",
      link: "../icons/government.svg"
    },
    {
      industry: "Manufacturing and Production",
      link: "../icons/manufacturing.svg"
    },
    {
      industry: "Entertainment and Media",
      link: "../icons/code.svg"
    },
    {
      industry: "Nonprofit and Social Services",
      link: "../icons/code.svg"
    },
    {
    industry: "Other",
    link: "../icons/code.svg"
    }
  ];
  